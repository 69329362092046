import logo from './assets/images/logo_title.svg';

const config = {
  authentication: {
    logo,
    providers: {
      email: {
        enable: true,
      },
      google: {
        enable: false,
        environments: {
          development: {
            authUrl: '/users/auth/google_oauth2'
          }
        }
      },
      developer: {
        enable: false,
        environments: {
          development: {
            authUrl: '/users/auth/developer'
          }
        }
      }
    },
  }
};

export default config;
